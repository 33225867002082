<template>
  <b-card title="Need help? 🙌">
    <b-card-text>Want to say Hi?</b-card-text>
    <b-card-text>Do you need penetration testing service by top security researcher?</b-card-text>
    <b-card-text>Or just want to have a coffee with the founder?</b-card-text>
    <b-card-text>Drop a mail to <a href="mailto:info@securityinfinity.com">info@securityinfinity.com</a></b-card-text>
    <b-card-text>Tweet to @<a href="https://twitter.com/athuljayaram">athuljayaram</a></b-card-text>


 </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
